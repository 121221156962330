import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
export default function ProjectCard({
	projectSection,
	projectId,
	projectName,
	projectImg,
	projectMeter,
	projectLocation,
	projectDate,
}) {
	return (
		<motion.div
			initial={{ opacity: 0, y: '15%' }}
			whileInView={{ opacity: 1, y: '0' }}
			viewport={{ once: true }}
			transition={{
				duration: '.5',
				ease: 'easeInOut',
			}}
			preventScrollReset={true}
			className=' md:w-2/5 md:min-w-2/5   border-4 border-[#141414] relative overflow-hidden'
		>
			<Link
				reloadDocument
				to={`/projects/${projectSection}/${projectId}`}
				className=' w-full h-full '
			>
				<img
					className={` object-cover min-h-full min-w-full z-10`}
					src={projectImg}
					alt={projectName}
				/>
				<div className='absolute top-0 bottom-0 left-0 right-0 overlay transition-all duration-500 ease-in-out opacity-0 hover:opacity-100 bg-[#141414b7]  z-20 flex items-center justify-center capitalize'>
					<h2 className='text-xs  lg:text-[1.2rem] leading-7 text-center p-10 uppercase font-light'>
						{projectName}
					</h2>
					<span className='date text-xs lg:text-sm font-extralight absolute bottom-0 left-0 p-3 md:p-5 lg:p-7'>
						{' '}
						{projectDate}
					</span>
					<span className='meter text-xs lg:text-sm font-extralight absolute bottom-0 right-0 p-3 md:p-5 lg:p-7'>
						{' '}
						{projectMeter} &#13217;{' '}
					</span>
					<span className='locatoin text-xs lg:text-sm font-extralight absolute top-0 left-0 p-3 md:p-5 lg:p-7'>
						{' '}
						{projectLocation}
					</span>
				</div>
			</Link>
		</motion.div>
	);
}
