// import Link from "next/link"
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
export default function ProjectHero({ project, section }) {
    return (
        <main className="project-hero p-5 xl:px-10 relative h-screen md:h-96 lg:h-[60vh] xl:h-screen w-full    overflow-hidden   bg-[#141414] bg-opacity-50">

            <img 
                
                className={`transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
                src={project.img} alt={project.name} />

            <div className="container mx-auto h-full flex flex-col items-start justify-center relative">
                <div className="project-title w-full flex flex-col md:flex-row items-start justify-start md:justify-between md:w-2/3  gap-10 md:gap-20  lg:gap-32 ">
                    <Link to={`/projects/${section}`} className="transition-all duration-500 ease-in-out hover:opacity-60 opacity-100 w-12 md:w-16 lg:w-20">
                        <motion.svg
                            initial={{ opacity: 0, x: '100%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, x: '0%' }}
                            transition={{
                                delay:.5,duration: '.7'
                            }}
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="arrow-left"><rect width="256" height="256" fill="none"></rect><line x1="216" x2="40" y1="128" y2="128" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8"></line><polyline fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" points="112 56 40 128 112 200"></polyline></motion.svg>
                    </Link>
                    <div className="content w-full gap-5 flex flex-col items-start">
                        <motion.h1
                            initial={{ opacity: 0, x: '-50%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, x: '0%' }}
                            transition={{
                                delay:.5,duration: '.7'
                            }}
                            className="capitalize text-4xl font-semibold lg:text-6xl ">{project.name}</motion.h1>
                        <motion.p
                        className=' font-normal'
                            initial={{ opacity: 0, y: '100%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, y: '0%' }}
                            transition={{
                                delay:.5,duration: '1'
                            }
                        }
                        >{project.desc}
                        </motion.p>
                    </div>
                </div>
            </div>
        </main >
    )
}
