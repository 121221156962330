import { motion } from 'framer-motion';

export const Numbers = () => {
  return (
    <ul className={`numbers flex flex-wrap items-center justify-center  transition-all ease-in-out _montserrat my-36  w-full text-xs md:text-sm lg:text-lg`}>
                        <motion.li
                                initial={{ opacity: 0, y: '100%' }}
                                viewport={{ once: true }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                transition={{ duration: '.5' }}
                                className=' flex flex-col w-1/2 md:w-1/4 text-center items-center justify-center gap-y-5 p-5'
                            >
                                <span className='text-3xl md:text-4xl lg:text-7xl'>2020</span>
                                <span className='uppercase'>in business since</span>
                               
                        </motion.li>
                        <motion.li
                                    initial={{ opacity: 0, y: '100%' }}
                                    viewport={{ once: true }}
                                    whileInView={{ opacity: 1, y: '0%' }}
                                    transition={{ duration: '.5', delay: .2 }}
                                    className=' flex flex-col w-1/2 md:w-1/4 text-center items-center justify-center gap-y-5 p-5'
                                    >
                                        <span className='text-3xl md:text-4xl lg:text-7xl'>115</span>
                                        <span className='uppercase'>PROJECTS COMPLETED</span>
                                       
                               
                        </motion.li>
                        <motion.li
                                        initial={{ opacity: 0, y: '100%' }}
                                        viewport={{ once: true }}
                                        whileInView={{ opacity: 1, y: '0%' }}
                                        transition={{
                                            duration: '.5', delay: .4
                                        }}
                                        className=' flex flex-col w-1/2 md:w-1/4 text-center items-center justify-center gap-y-5 p-5'
                                        >
                                            <span className='text-3xl md:text-4xl lg:text-7xl'>150</span>
                                            <span className='uppercase'>SATISFIED CLIENTS</span>
                                           
                                       
                                        
                        </motion.li>
                        <motion.li
                                        initial={{ opacity: 0, y: '100%' }}
                                        viewport={{ once: true }}
                                        whileInView={{ opacity: 1, y: '0%' }}
                                        transition={{ duration: '.5', delay: .6 }}
                                        className=' flex flex-col w-1/2 md:w-1/4 text-center items-center justify-center gap-y-5 p-5'
                                        >
                                            <span className='text-3xl md:text-4xl lg:text-7xl'>10+</span>
                                            <span className='uppercase'>COUNTRIES</span>
                                           
                               
                        </motion.li>
                       
                  </ul>
  )
}
