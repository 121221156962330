import { useEffect } from "react";
import { useFormContext } from "../context/FormContext";

export const ContactForm = () => {
 
  const { isFormOpened, setIsFormOpened } = useFormContext()
    useEffect(() => {
        if (isFormOpened) {
            document.body.style.overflow = 'hidden';

            document.body.style.pointerEvents = 'none';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.pointerEvents = 'auto';

        }
    }, [isFormOpened])
  return (
    <div className={`${isFormOpened ? "opacity-100 pointer-events-auto" : "pointer-events-none opacity-0"} transition-all ease-in-out duration-500 bg-[#141414] bg-opacity-95 fixed top-0 left-0 h-screen w-screen z-50 flex items-center justify-center`}>

            <div className="container   mx-auto flex  gap-16 items-center justify-center  p-5 z-10">
                <div className="contact-info relative  text-sm font-light flex flex-col items-center md:items-start md:justify-start  justify-center  w-full lg:w-2/3 gap-16 bg-[#121212] p-10 py-20 lg:p-20 xl:p-32">
                     <div className={`bg-title absolute top-44  text-[#222222] left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none uppercase text-8xl md:text-[10rem] lg:text-[12rem] xl:text-[16rem] tracking-widest z-0  overflow-hidden w-full text-center ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-300" : "-translate-y-24  opacity-0 "}`}>Form</div>
                    <div
                        onClick={() => {
                            setIsFormOpened(false)
                        }}
                        className={`_exit absolute top-5 right-5 flex w-7 h-7 flex-col items-center justify-center hover:opacity-70 opacity-100 transition-all duration-500 ease-in-out cursor-pointer`}>

                        <div className="line w-7 h-[1px] transition-all duration-500 ease-in-out pointer-events-none bg-white rotate-45"></div>

                        <div className="line w-7 h-[1px] transition-all duration-500 ease-in-out pointer-events-none bg-white -rotate-45"></div>

                    </div>
                    <h2 className={`text-2xl font-bold ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-150" : "translate-y-24  opacity-0"}  _montserrat`}>Do not hesitate to contact us</h2>
                    <form className=" _montserrat flex-wrap flex items-start justify-between  gap-5 lg:gap-auto w-full ">
                        <input className={`bg-transparent transition-all ease-in-out duration-500 w-full md:w-5/12 text-white  border-b border-b-white border-opacity-30 hover:border-opacity-100 focus:border-opacity-100 px-4 py-2 outline-none focus:outline-none ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-200" : "translate-y-24  opacity-0"}`} type="text" placeholder="Name" name="name" id="name" />
                        <input className={`bg-transparent transition-all ease-in-out duration-500 w-full md:w-5/12 text-white  border-b border-b-white border-opacity-30 hover:border-opacity-100 focus:border-opacity-100 px-4 py-2 outline-none focus:outline-none ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-300" : "translate-y-24  opacity-0"}`} type="text" placeholder="City" name="city" id="city" />
                        <input className={`bg-transparent transition-all ease-in-out duration-500 w-full md:w-5/12 text-white  border-b border-b-white border-opacity-30 hover:border-opacity-100 focus:border-opacity-100 px-4 py-2 outline-none focus:outline-none ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-500" : "translate-y-24  opacity-0"}`} type="email" placeholder="Email" name="email" id="email" />
                        <input className={`bg-transparent transition-all ease-in-out duration-500 w-full md:w-5/12 text-white  border-b border-b-white border-opacity-30 hover:border-opacity-100 focus:border-opacity-100 px-4 py-2 outline-none focus:outline-none ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-700" : "translate-y-24  opacity-0"}`} type="text" placeholder="Phone" name="phone" id="phone" />
                        <input className={`bg-transparent transition-all ease-in-out duration-500 w-full md:w-5/12 text-white  border-b border-b-white border-opacity-30 hover:border-opacity-100 focus:border-opacity-100 px-4 py-2 outline-none focus:outline-none ${isFormOpened ? "duration-700 opacity-100 translate-y-0 delay-1000" : "translate-y-24  opacity-0"}`} type="text" placeholder="Message" name="message" id="message" />
                    </form>
                    <div className={`cta transition-all ease-in-out  _montserrat ${isFormOpened ? "duration-500 opacity-100 translate-y-0 delay-[1300ms]" : "translate-y-24  opacity-0"}`}>
                        <button className="uppercase duration-500 px-24 py-4 text-xs border border-white hover:bg-white hover:text-black transition-all ease-in-out">send request</button>
                    </div>
                </div>

            </div>
           

        </div>
  )
}
