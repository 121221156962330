import { motion } from 'framer-motion';

export default function ProjectPlan() {
    return (

            <div className="container  mx-auto flex flex-col md:flex-row items-center justify-evenly gap-16">
            <motion.img
                initial={{ opacity: 0, y: '30%' }}
                viewport={{ once: true }}
                whileInView={{ opacity: 1, y: '0%' }}
                transition={{
                    duration: '.5'
                }}
                className="w-full md:w-1/2" src="/plan-w.png" alt="blueprint" />
                <div className="w-full p-10 md:w-1/2 plannings flex flex-col items-start justify-start gap-10">
                <motion.h2
                    initial={{ opacity: 0, y: '100%' }}
                    viewport={{ once: true }}
                    whileInView={{ opacity: 1, y: '0%' }}
                    transition={{
                        duration: '.5', delay: .7
                    }}
                    className="text-4xl capitalize font-semibold ">Planning</motion.h2>
                    <div className="p-10plan-text flex-col flex items-start justify-start gap-5 _montserrat font-light ">
                    <motion.p
                        initial={{ opacity: 0, y: '100%' }}
                        viewport={{ once: true }}
                        whileInView={{ opacity: .6, y: '0%' }}
                        transition={{
                            duration: '.5', delay: 1
                        }}
                    >The space of the future apartments was already organically planned. When the team got to work, all they had to do was to place the zones correctly while taking into account the specific features of the apartments. One of the best views of the Dnieper River and the historical center of Kyiv opens up from the ninth floor of the modern residential complex. Therefore, the team placed a rounded living room with panoramic windows to admire all the best landscapes and sunsets in the capital.

                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, y: '100%' }}
                        viewport={{ once: true }}
                        whileInView={{ opacity: .6, y: '0%' }}
                        transition={{
                            duration: '.5', delay: 1.3
                        }}
                    >In general, the Corine consists of a spacious entrance hall, a living room combined with a dining room and kitchen, a master area with a wardrobe and a bathroom, and an office that transforms into a guest bedroom for the son at the clients’ request.

                    </motion.p>
                    </div>
                </div>
            </div>

    )
}
