import { createContext, useContext, useState } from "react";




const FormContext = createContext({
	isFormOpened: false,
	setIsFormOpened: () => false,
	isSliderOpened: false,
	setIsSliderOpened: () => false,
	scrollTo: '',
	setScrollTo: () => '',
	currImg: 0,
	setCurrImg: () => 0,
});

export const FormContextProvider = ({ children }) => {
	const [isFormOpened, setIsFormOpened] = useState(false);
	const [isSliderOpened, setIsSliderOpened] = useState(false);
	const [scrollTo, setScrollTo] = useState('');
	const [currImg, setCurrImg] = useState(0);
	return (
		<FormContext.Provider
			value={{
				isFormOpened,
				setIsFormOpened,
				scrollTo,
				setScrollTo,
				currImg,
				setCurrImg,
				isSliderOpened,
				setIsSliderOpened,
			}}
		>
			{children}
		</FormContext.Provider>
	);
};

export const useFormContext = () => useContext(FormContext);
