import JobBtn from "./common/JobBtn";
import { motion } from 'framer-motion';

export const Careers = () => {
  return (

        <motion.div
            initial={{ opacity: 0, y: '50%' }}
            whileInView={{ opacity: 1, y: '0%' }}
            viewport={{ once: true }}
            transition={{ duration: '.5' }}
            className="text-white relative  px-5 py-24 md:py-44 lg:py-64 ">
            <div className="container flex items-center justify-center mx-auto ">
                <div className="bg-title absolute top-16 text-[#222222] left-1/2 -translate-x-1/2 pointer-events-none uppercase text-7xl md:top-20 md:text-9xl lg:top-24 lg:text-[14rem] xl:text-[18rem] tracking-widest z-0 overflow-hidden w-full text-center"><motion.span initial={{ opacity: 0, y: '100%' }}
                    whileInView={{ opacity: 1, y: '0%' }}
                    viewport={{ once: true }}
                    transition={{ duration: '.5', delay: .5 }}>careers</motion.span></div>
                <span className="vertical-line h-0 md:h-14 lg:h-24 absolute  md:top-12 lg:top-16 left-1/2 -translate-x-1/2 w-[.5px] opacity-20 bg-white"></span>
                <span className="vertical-line h-0 md:h-14 lg:h-24 absolute  md:bottom-12 lg:bottom-16 left-1/2 -translate-x-1/2 w-[.5px] opacity-20 bg-white"></span>
                <div className="about  w-4/5   gap-10 lg:gap-20  flex flex-col items-start justify-center ">
                    <div className="title font-semibold text-3xl md:text-4xl text-start w-2/3 lg:w-80 z-10">
                        <motion.h2 initial={{ opacity: 0, y: '100%' }}
                            whileInView={{ opacity: 1, y: '0%' }}
                            viewport={{ once: true }}
                            transition={{ duration: '.5', delay: .5 }}>A big day to join our team</motion.h2>
                    </div>
                    <div className="content text-md _montserrat font-light  flex flex-col lg:flex-row items-start justify-between gap-5 lg:gap-32 z-10 lg:leading-8">
                        <motion.p initial={{ opacity: 0, y: '100%' }}
                            whileInView={{ opacity: .6, y: '0%' }}
                            viewport={{ once: true }}
                            transition={{ duration: '.5', delay: .7 }} className="lg:w-1/2 opacity-60 ">YODEZEEN is a great deal. It is a great deal for those who are mad in love with architectural and interior projects that are always a challenge. A challenge you would never want to skip. The cooperation with our studio is based on creative thinking, openness within our team, and never-ending growth.
                        </motion.p>
                        <div className="hiring lg:w-1/2 flex flex-wrap items-start justify-end ">
                            <motion.div
                                initial={{ opacity: 0, y: '100%' }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                viewport={{ once: true }}
                                transition={{ duration: '.5', delay: 1 }}
                            ><JobBtn
                                    place={'remote'}
                                    title={'Graphic Designer'}
                                    req={'Adobe package (Indesign, Photoshop, Illustrator)Minimum of 2 years of hands-on experience Brand identity design cases Experience in working with printing offices'}
                                    desc={'Working on brand positioning; advertisement, social, and media solutions The field of commercial projects’ identity (offices, restaurants, housing estate, etc.) Printing products design and convert, implementation support'} /></motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: '100%' }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                viewport={{ once: true }}
                                transition={{ duration: '.5', delay: 1.3 }}
                            > <JobBtn
                                place={'cairo, Egypt'}
                                title={'Interior Designer'}
                                req={'A sense of our style 3Ds Max Work experience of 2 years+'}
                                    desc={'Design project conceptual solution development Photorealistic rendering of the design solution via 3Ds Max Relying on exclusivity & artistic identity'} /></motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: '100%' }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                viewport={{ once: true }}
                                transition={{ duration: '.5', delay: 1.5 }}
                            > <JobBtn
                                    place={'Singapore'}
                                    title={'PR Manager (Singapore project)'}
                                    req={'PR experience in the field of architecture & design Deep understanding and experience in the Singapore real estate market Advertising, marketing, PR strategies, CJM cases Advanced English Copywriting skills'}
                                    desc={'Singapore real estate project management Coordination of the marketing budget, project analytics Creation of advertising, marketing, and PR strategies for the project, CJM and participation in branding development Event releases, articles, interviews creation, Pitching'} /></motion.div>




                        </div>
                    </div>
                </div>
            </div>

            </motion.div>
  )
}
