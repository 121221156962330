import { motion } from 'framer-motion';
import { AnimatePresence } from "framer-motion";
import projects from "../../projects.json"
import ProjectCard from '../ProjectCard/ProjectCard';
export default function ProjectsGallery({ projectCat }) {

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: .5 }}
      exit={{ opacity: 0 }}
      className="w-full flex flex-wrap justify-center items-center gap-10  px-5 md:px-0 max-h-min ">
      <AnimatePresence>
        {projectCat === "all" ?
          projects.map(project => {
            return (
							<ProjectCard
								key={project.id}
								projectSection={project.section}
								projectId={project.id}
								projectName={project.name}
								projectImg={project.img}
								projectDate={project.date}
								projectMeter={project.meter}
								projectLocation={project.location}
							/>
						);
          })
          :
          projects.filter(project => project.section === projectCat).map(project => {
            return (
							<ProjectCard
								key={project.id}
								projectSection={project.section}
								projectId={project.id}
								projectName={project.name}
								projectImg={project.img}
								projectDate={project.date}
								projectMeter={project.meter}
								projectLocation={project.location}
							/>
						);
          })
        }
      </AnimatePresence> 

    </motion.section>
  )
}
