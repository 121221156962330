

import projects from "../../projects.json"
import { motion } from 'framer-motion';
import { Footer } from "../Footer/Footer"
import ProjectsGallery from "../ProjectsGallery/ProjectsGallery"
import ProjectAbout from "./common/ProjectAbout"
import ProjectHero from "./common/ProjectHero"
import ProjectPlan from "./common/ProjectPlan"
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useFormContext } from '../context/FormContext';
import { ProjectImages } from './common/ProjectImages';

export default function ProjectDetails() {
	const { category, projectDetails } = useParams();
	const { isSliderOpened } = useFormContext();
	let project = projects.find((p) => p.id === projectDetails);

	const [isCopied, setIsCopied] = useState(false);
	useEffect(() => {
		setIsCopied(false);
	}, [projectDetails]);
	return (
		<motion.div
			className={`relative ${isSliderOpened ? '' : ''}`}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ delay: 0.5, duration: 0.5 }}
		>
			<Helmet>
				<title>{project.name}</title>
				<meta
					name='description'
					content={project.desc}
				/>
			</Helmet>
			<div
				className='fixed bg-white text-[#141414] font-normal bg-opacity-50 hover:bg-opacity-100 transition-all duration-300 cursor-pointer rounded-sm  bottom-5 right-5 p-2 z-20 flex items-center justify-between gap-2 uppercase'
				onClick={() => {
					navigator.clipboard.writeText(
						`https://islaarchitects.com/projects/${category}/${projectDetails}`
					);
					setIsCopied(true);
				}}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='w-5 inline-block'
					enableBackground='new 0 0 512 512'
					fill='#141414'
					viewBox='0 0 512 512'
					id='share'
				>
					<path d='M118.095 178.063c-42.117 0-76.382-34.268-76.382-76.388s34.265-76.388 76.382-76.388c42.124 0 76.395 34.268 76.395 76.388S160.219 178.063 118.095 178.063zM118.095 39.69c-34.175 0-61.979 27.807-61.979 61.985s27.804 61.985 61.979 61.985c34.182 0 61.991-27.807 61.991-61.985S152.277 39.69 118.095 39.69zM417.287 383.839c-42.118 0-76.383-34.268-76.383-76.39 0-42.12 34.265-76.388 76.383-76.388 42.117 0 76.382 34.268 76.382 76.388C493.669 349.571 459.404 383.839 417.287 383.839zM417.287 245.464c-34.176 0-61.98 27.807-61.98 61.985 0 34.18 27.805 61.987 61.98 61.987s61.979-27.808 61.979-61.987C479.267 273.271 451.463 245.464 417.287 245.464zM95.651 487.649c-42.118 0-76.383-34.265-76.383-76.383 0-42.123 34.265-76.393 76.383-76.393 42.124 0 76.395 34.27 76.395 76.393C172.045 453.385 137.775 487.649 95.651 487.649zM95.651 349.276c-34.176 0-61.979 27.809-61.979 61.99 0 34.176 27.804 61.98 61.979 61.98 34.182 0 61.991-27.805 61.991-61.98C157.642 377.085 129.833 349.276 95.651 349.276z'></path>
					<path d='M359.215 277.102c-1.344 0-2.703-.375-3.914-1.161L166.266 153.242c-3.336-2.166-4.285-6.626-2.12-9.962s6.625-4.286 9.961-2.12l189.035 122.698c3.336 2.166 4.285 6.626 2.12 9.962C363.883 275.945 361.572 277.102 359.215 277.102zM161.988 398.8c-3.11 0-5.978-2.029-6.904-5.163-1.126-3.814 1.053-7.82 4.867-8.946l191.807-56.646c3.818-1.128 7.821 1.053 8.946 4.866 1.127 3.814-1.052 7.82-4.866 8.946l-191.808 56.646C163.351 398.704 162.664 398.8 161.988 398.8z'></path>
				</svg>
				<span className='text-xs'>
					{isCopied ? 'project link copied to clipboard' : 'share'}
				</span>
			</div>
			<ProjectHero
				project={project}
				section={project.section}
			/>
			<ProjectAbout />
			<ProjectPlan />
			<ProjectImages project={project} />

			<div className='container mx-auto px-5 xl:px-10 py-16 gap-10 relative'>
				<div className='bg-title absolute top-10 text-[#222222] left-1/2 -translate-x-1/2 pointer-events-none uppercase text-6xl md:text-[9rem] lg:text-[12rem]  tracking-widest -z-10 overflow-hidden w-full text-center'>
					<motion.span
						initial={{ opacity: 0, y: '50%' }}
						whileInView={{ opacity: 1, y: '0%' }}
						viewport={{ once: true }}
						transition={{ duration: '.5', delay: 0.5 }}
					>
						more
					</motion.span>
				</div>
				<h1 className='text-xl md:text-2xl mb-5 lg:text-3xl py-10  font-semibold uppercase text-center'>
					more {project.section} projects
				</h1>
				<ProjectsGallery
					projectCat={project.section}
					pGallery={true}
				/>
			</div>
			<Footer />
		</motion.div>
	);
}
