
import { useState } from "react"

export default function JobBtn({ place, title, req, desc }) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className="open-vacancy w-full flex-col flex items-start justify-center gap-5">
            <button onClick={() => {
                setIsOpen(!isOpen)
            }} className="flex flex-col items-start text-start justify-center capitalize">
                <h3 className="job-title font-bold ">

                    {title}
                </h3>
                <span className="work-place opacity-60">{place}</span>
            </button>
            <div className={`${isOpen ? "h-96 opacity-100 pointer-events-auto" : "h-0 opacity-0 pointer-events-none"} relative content flex gap-5 flex-col items-start justify-center transition-all duration-500 ease-in-out overflow-hidden`}>
                <div className="job-req flex flex-col items-start text-start justify-center gap-3 opacity-60">
                    <h3 className="font-bold">Hiring specification:</h3>
                    <p>{req}</p>
                </div>
                <div className="job-desc flex flex-col items-start text-start justify-center gap-3 opacity-60">
                    <h3 className="font-bold">Areas of responsibility:</h3>
                    <p>{desc}
                    </p>
                </div>
            </div>
        </div>
    )
}
