import { motion } from 'framer-motion';
import ProjectsCat from '../ProjectsCat/ProjectsCat';
import { Footer } from '../Footer/Footer';
import ProjectsGallery from '../ProjectsGallery/ProjectsGallery';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import projects from "../../projects.json"
export const Projects = () => {

	
	const { category } = useParams();
	return (
		<motion.section
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ delay: 0.5, duration: 0.5 }}
			exit={{ opacity: 0 }}
			className='min-h-screen px-5 xl:px-10 w-full pt-24 lg:pt-36 text-white'
		>
			
			
				<Helmet>
				<title className=''>
					 {category === 'all' ? 'Isla Projects' : projects.filter(p=> p.section === category)[0].sectionName}
				</title>
				<meta
					name='description'
					content={`All over the world Leading you into the Innovative, Safety, Luxurious, and Artistic world of Architecture and Interior Design`}
				/>
			</Helmet>
			<h1 className=' text-[#222222] pointer-events-none uppercase text-3xl  md:text-6xl tracking-widest z-0 overflow-hidden  w-full flex items-center flex-col justify-center text-center'>
				<span>{category.replace('-', ' ') }</span>
				<span>Projects </span>
			</h1>

			<div className='container mx-auto flex flex-col items-center justify-center gap-10 py-10 lg:py-20 '>
				<ProjectsCat projectCat={category} />

				<ProjectsGallery projectCat={category} />
			</div>

			<Footer />
			
			
		</motion.section>
	);
};
