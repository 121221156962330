import { Link } from 'react-router-dom';
export default function ProjectsCat({ projectCat }) {
    
    return (
			<div className='projects-cat capitalize flex items-center justify-center  text-[.70rem] gap-2 md:gap-5 md:text-lg font-semibold my-5'>
				<Link
					preventScrollReset={true}
					to={'/projects/all'}
					id='all'
					className={`${
						projectCat === 'all' ? 'opacity-100' : 'opacity-30'
					} transition-all duration-500 ease-in-out`}
				>
					all
				</Link>

				<Link
					preventScrollReset={true}
					to={'/projects/interior-design'}
					id='interior-design'
					className={`${
						projectCat === 'interior-design' ? 'opacity-100' : 'opacity-30'
					} transition-all duration-500 ease-in-out`}
				>
					interior design
				</Link>
				<Link
					preventScrollReset={true}
					to={'/projects/site-execution'}
					id='site-execution'
					className={`${
						projectCat === 'site-execution' ? 'opacity-100' : 'opacity-30'
					} transition-all duration-500 ease-in-out`}
				>
					site execution
				</Link>

				<Link
					preventScrollReset={true}
					to={'/projects/architects'}
					id='architects'
					className={`${
						projectCat === 'architects' ? 'opacity-100' : 'opacity-30'
					} transition-all duration-500 ease-in-out`}
				>
					architects
				</Link>
				<Link
					preventScrollReset={true}
					to={'/projects/kitchens'}
					id='kitchens'
					className={`${
						projectCat === 'kitchens' ? 'opacity-100' : 'opacity-30'
					} transition-all duration-500 ease-in-out`}
				>
					kitchens
				</Link>
			</div>
		);
}
