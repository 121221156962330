import { Nav } from '../Nav/Nav'
import { Outlet, ScrollRestoration } from 'react-router-dom'

export const Layout = () => {
  return <>
  <Nav/>
  <Outlet/>
  <ScrollRestoration/>
  </>
}
