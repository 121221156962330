import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Layout } from './Components/Layout/Layout';
import { Home } from './Components/Home/Home';
import { Projects } from './Components/Projects/Projects';
import { NotFound } from './Components/NotFound/NotFound';
import ProjectDetails from './Components/ProjectDetails/ProjectDetails';
import { FormContextProvider } from './Components/context/FormContext';
import { Redirect } from './Components/Redirect/Redirect';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		children: [
			{ path: '', element: <Home /> },
			{ path: 'projects/:category', element: <Redirect currPage={'Projects'}><Projects /></Redirect> },
			{ path: 'projects/:category/:projectDetails', element: <Redirect currPage={'ProjectDetails'}><ProjectDetails /></Redirect>},
			{ path: '404', element: <NotFound /> },
		],
	},
	{ path: '*', element: <NotFound /> },
]);

function App() {
	return (
		<FormContextProvider>

			<RouterProvider router={router} />
		</FormContextProvider>
	);
}

export default App;
