import { motion } from 'framer-motion';
import { Hero } from '../Hero/Hero';
import { About } from '../About/About';
import { Sections } from '../Sections/Sections';
import { Careers } from '../Careers/Careers';
import { Footer } from '../Footer/Footer';
import { ContactForm } from '../ContactForm/ContactForm';
import { useEffect } from 'react';
import { useFormContext } from '../context/FormContext';
import { Numbers } from '../Numbers/Numbers';
import { Helmet } from 'react-helmet';
import { YouTube } from '../YouTube/YouTube';
import youtube from '../../youtube.json'




export const Home = () => {

  const { scrollTo } = useFormContext()
    useEffect(() => {
      document
      .getElementById(scrollTo)
      ?.scrollIntoView({ behavior: 'smooth' });
    }, [scrollTo])

  return (
    <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: .5, duration: .5 }}
            exit={{ opacity: 0 }}
            
        >
          <Helmet>
				<title >Isla Architects</title>
        <meta
          name="description"
          content={`All over the world Leading you into the Innovative, Safety, Luxurious, and Artistic world of Architecture and Interior Design`}
        />
			</Helmet>
            <Hero />
            {/* <div className="youtube flex overflow-x-scroll gap-10  p-10">
			{youtube.map( video=>{
				return <YouTube src={video.src} title={video.title}/>
			})}
			
			</div> */}
            <span id="about"></span>
            <About />
            <Sections />
            <Numbers/>
            <span id="careers"></span>
            <Careers />
            <span id='contact'></span>
            <Footer />
            <ContactForm />
    </motion.div>
  )
}
