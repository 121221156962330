import { motion } from 'framer-motion';

export default function ProjectAbout() {
    return (
        <div id="projectAbout" className=" relative text-white px-5 py-24 md:py-44 lg:py-64  ">
            <div className="container flex flex-col items-center justify-center mx-auto">
                <div className="bg-title absolute top-10 text-[#222222] left-1/2 -translate-x-1/2 pointer-events-none uppercase  md:top-20 text-6xl md:text-[9rem] lg:text-[12rem] tracking-widest z-0 overflow-hidden w-full text-center"><motion.span initial={{ opacity: 0, y: '50%' }}
                    whileInView={{ opacity: 1, y: '0%' }}
                    viewport={{ once: true }}
                    transition={{ duration: '.5', delay: .5 }}>about</motion.span></div>
                <span className="vertical-line h-0 md:h-14 lg:h-24 absolute  md:top-12 lg:top-16 left-1/2 -translate-x-1/2 w-[.5px] opacity-20 bg-white"></span>
                <span className="vertical-line h-0 md:h-14 lg:h-24 absolute  md:bottom-12 lg:bottom-16 left-1/2 -translate-x-1/2 w-[.5px] opacity-20 bg-white"></span>
                <div className="project-about  w-4/5 md:w-3/5   gap-10 lg:gap-20  flex flex-col items-start justify-center ">
                    <div className="title font-semibold text-3xl md:text-4xl text-start w-2/3 lg:w-80 z-10">
                        <motion.h2
                            initial={{ opacity: 0, y: '50%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, y: '0%' }}
                            transition={{
                                duration: '.5', delay: .7
                            }}
                        >Brief</motion.h2>
                    </div>
                    <div className=" content text-md _montserrat font-light  flex flex-col lg:flex-row items-start  justify-center lg:justify-between gap-10 lg:gap-10 z-10 lg:leading-8">
                        <motion.p
                            initial={{ opacity: 0, y: '50%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: .6, y: '0%' }}
                            transition={{
                                duration: '.5', delay: 1
                            }}
                            className="opacity-60 lg:w-1/2">A middle-aged couple living in Switzerland but traveling to Kyiv for business surprised the studio team by entrusting them with designing their apartment in Kyiv. The clients asked the designers to design the interior in the YODEZEEN style, and for the first time, came to evaluate the result when the project was already fully implemented. When they saw the apartment in person, there was no doubt that it was exactly what they wanted.



                        </motion.p>
                        <div className="details lg:w-1/2  w-full flex flex-col items-start justify-start gap-5">
                            <motion.div
                                initial={{ opacity: 0, y: '50%' }}
                                viewport={{ once: true }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                transition={{
                                    duration: '.5', delay: 1.3
                                }}
                                className="location flex items-center justify-start lg:ps-16 w-full gap-10">

                                <svg className="w-8" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="location-pin"><path d="M24.43 40.153a1 1 0 0 0 1.658 0l9.436-14.018a11.239 11.239 0 0 0 1.725-5.976c0-6.376-5.383-11.564-12-11.564s-12 5.188-12 11.564c0 2.114.599 4.184 1.749 6.012l9.432 13.982zm.82-29.559c5.514 0 10 4.291 10 9.564a9.215 9.215 0 0 1-1.402 4.886l-8.59 12.76-8.584-12.726a9.232 9.232 0 0 1-1.424-4.92c0-5.273 4.486-9.564 10-9.564z"></path><path d="M25.25 25.616c3.135 0 5.686-2.467 5.686-5.5s-2.55-5.5-5.686-5.5-5.686 2.467-5.686 5.5 2.551 5.5 5.686 5.5zm0-9c2.032 0 3.686 1.57 3.686 3.5s-1.653 3.5-3.686 3.5-3.686-1.57-3.686-3.5 1.654-3.5 3.686-3.5z"></path></svg>

                                <div className="text flex items-start justify-start flex-col">
                                    <span className="bold capitalize">location:</span>
                                    <span className="opacity-60">cairo, Egypt</span>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: '50%' }}
                                viewport={{ once: true }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                transition={{
                                    duration: '.5', delay: 1.5
                                }}
                                className="size flex items-center justify-start lg:ps-16 w-full gap-10">


                                <svg className="w-8 h-8" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" id="pencil">
                                    <path d="m9.156 1.781-.344.375-3.218 3.188-.344.343.344.375 8.281 8.25-8.406 8.407a.5.5 0 0 0-.125.093v.032a.5.5 0 0 0-.125.156l-1.157 1.125a2.333 2.333 0 0 0 0 3.281l.844.844c.9.9 2.382.9 3.282 0l1.156-1.188a.5.5 0 0 0 .125-.093.5.5 0 0 0 .062-.094L18 18.437l8.25 8.25.344.344.343-.343 3.22-3.188.343-.375-.344-.344-8.25-8.25 4.532-4.562a.5.5 0 0 0 .156-.156.5.5 0 0 0 .062-.094l1.719-5.125a.5.5 0 0 0-.625-.657.5.5 0 0 0-.031.032l-5 1.656a.5.5 0 0 0-.313.156.5.5 0 0 0-.156.188l-4.469 4.437L9.5 2.156l-.344-.375zm0 1.438 7.906 7.906-1.406 1.406-.437-.437-1.063 1.094-.719-.72.344-.374-.687-.719-.375.375-.719-.719 1.094-1.062-.719-.719-1.094 1.094-.719-.719.376-.375-.688-.719-.375.375-.719-.719 1.063-1.062-.688-.719L8.47 7.47 7.75 6.75l.344-.344-.688-.718-.375.375-.375-.375 2.5-2.47zm17.938 2-1.125 3.406-2.282-2.281 3.407-1.125zM22.78 6.844l1 1-.218.218h-.032L7.375 24.189l-.969-.97L22.781 6.845zM24.47 8.53l1 1.031L9.125 25.907l-1-1L24.25 8.781V8.75l.219-.219zm-18.782.125-2.125 2.125.72.688 2.124-2.125-.718-.688zm1.438 1.406-2.844 2.813.719.719 2.813-2.813-.688-.719zm-4.25 1.407-.719.719.719.687.688-.688-.688-.718zm.688 2.125-.688.719.688.687.718-.688-.718-.718zm17.625 1.656 7.906 7.875-2.5 2.5-.344-.344 1.063-1.093-.72-.688-1.062 1.063-.718-.688.375-.375-.72-.719-.374.375-.719-.718 1.094-1.094-.719-.688-1.063 1.063L21.97 21l.375-.375-.719-.688-.344.344-.718-.718 1.062-1.063-.719-.719-1.062 1.094-.719-.719.375-.375-.438-.406 2.125-2.125zm-15.5 8.688 2.718 2.687-.937.906c-.52.52-1.324.52-1.844 0l-.844-.843a1.285 1.285 0 0 1 0-1.844l.907-.907zm15.562.25-2.813 2.843.688.719 2.844-2.844-.719-.718zm1.406 1.437-2.125 2.125.719.688 2.125-2.125-.719-.688zM17.72 27.75l-.719.688.719.718.718-.718-.718-.688zm2.125.688-.719.718.719.688.687-.688-.687-.718z">

                                    </path>
                                </svg>
                                <div className="text flex items-start justify-start flex-col">
                                    <span className="bold capitalize">Square meters:</span>
                                    <span className="opacity-60">2,583 ft² / 240m²</span>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: '50%' }}
                                viewport={{ once: true }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                transition={{
                                    duration: '.5', delay: 1.7
                                }}
                                className="date flex items-center justify-start lg:ps-16 w-full gap-10">

                                <svg className="w-7" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128" id="date">
                                    <path d="M60 60h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zM20 80h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm-80 32h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm-80 32h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8zm20 12h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4zm0-12h8v8h-8z"></path>
                                    <path d="M120 7h-9V2a2 2 0 0 0-4 0v16a3 3 0 0 1-6 0V2a2 2 0 0 0-4 0v5H71V2a2 2 0 0 0-4 0v16a3 3 0 0 1-6 0V2a2 2 0 0 0-4 0v5H31V2a2 2 0 0 0-4 0v16a3 3 0 0 1-6 0V2a2 2 0 0 0-4 0v5H8a8 8 0 0 0-8 8v105a8.024 8.024 0 0 0 8 8h112a8 8 0 0 0 8-8V15a8 8 0 0 0-8-8ZM98.04 21.653a6.98 6.98 0 0 0 11.92 0c.015.17.04.339.04.514a6 6 0 1 1-12 0c0-.175.025-.343.04-.514Zm-40 0a6.98 6.98 0 0 0 11.92 0c.015.17.04.339.04.514a6 6 0 1 1-12 0c0-.175.025-.343.04-.514Zm-40 0a6.98 6.98 0 0 0 11.92 0c.015.17.04.339.04.514a6 6 0 1 1-12 0c0-.175.025-.343.04-.514ZM124 120a4.005 4.005 0 0 1-4 4H8a4.005 4.005 0 0 1-4-4V40h120Zm0-84H4V15a4.005 4.005 0 0 1 4-4h9v4.047a10 10 0 1 0 14 0V11h26v4.047a10 10 0 1 0 14 0V11h26v4.047a10 10 0 1 0 14 0V11h9a4.005 4.005 0 0 1 4 4Z"></path>
                                </svg>

                                <div className="text flex items-start justify-start flex-col">
                                    <span className="bold capitalize">Date:</span>
                                    <span className="opacity-60">October 2021</span>
                                </div>
                            </motion.div>
                            {/* <motion.div
                                initial={{ opacity: 0, y: '50%' }}
                                viewport={{ once: true }}
                                whileInView={{ opacity: 1, y: '0%' }}
                                transition={{
                                    duration: '1', delay: 1.7
                                }}
                                className="team flex items-center justify-start lg:ps-16 w-full gap-10">

                                <svg className='w-8' fill="#ffffff" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 48 48" id="group"><path d="M155.469,79.103C155.009,79.037 154.52,79 154,79C150.17,79 148.031,81.021 147.211,82.028C147.078,82.201 147.007,82.413 147.007,82.632C147.007,82.638 147.007,82.644 147.006,82.649C147,83.019 147,83.509 147,84C147,84.552 147.448,85 148,85L155.172,85C155.059,84.682 155,84.344 155,84C155,84 155,84 155,84C155,82.862 155,81.506 155.004,80.705C155.004,80.135 155.167,79.58 155.469,79.103ZM178,85L158,85C157.735,85 157.48,84.895 157.293,84.707C157.105,84.52 157,84.265 157,84C157,82.865 157,81.515 157.004,80.711C157.004,80.709 157.004,80.707 157.004,80.705C157.004,80.475 157.084,80.253 157.229,80.075C158.47,78.658 162.22,75 168,75C174.542,75 177.827,78.651 178.832,80.028C178.943,80.197 179,80.388 179,80.583L179,84C179,84.265 178.895,84.52 178.707,84.707C178.52,84.895 178.265,85 178,85ZM180.828,85L188,85C188.552,85 189,84.552 189,84L189,82.631C189,82.41 188.927,82.196 188.793,82.021C187.969,81.021 185.829,79 182,79C181.507,79 181.042,79.033 180.604,79.093C180.863,79.546 181,80.06 181,80.585L181,84C181,84.344 180.941,84.682 180.828,85ZM154,67C151.24,67 149,69.24 149,72C149,74.76 151.24,77 154,77C156.76,77 159,74.76 159,72C159,69.24 156.76,67 154,67ZM182,67C179.24,67 177,69.24 177,72C177,74.76 179.24,77 182,77C184.76,77 187,74.76 187,72C187,69.24 184.76,67 182,67ZM168,59C164.137,59 161,62.137 161,66C161,69.863 164.137,73 168,73C171.863,73 175,69.863 175,66C175,62.137 171.863,59 168,59Z" transform="translate(-144 -48)"></path></svg>
                                <div className="text flex items-start justify-start flex-col w-full">
                                    <span className="bold capitalize">Team:</span>
                                    <span className="opacity-60 ">Abdullah Hassan, co-founder and lead architect, Abdullah Hassan, co-founder and lead architect</span>
                                </div>
                            </motion.div> */}


                        </div>

                    </div>
                </div>
                {/* <div className="share flex items-center justify-center py-16 lg:py-32 lg:ps-16 w-full gap-10">

                    <motion.span
                        initial={{ opacity: 0, y: '50%' }}
                        viewport={{ once: true }}
                        whileInView={{ opacity: .6, y: '0%' }}
                        transition={{
                            duration: '.5', delay: .5
                        }}
                        className='opacity-60 text-xl'>Share:</motion.span>
                    <ul className={`social-icons flex items-center justify-center gap-5 transition-all ease-in-out`}>
                        <motion.li
                            initial={{ opacity: 0, y: '50%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, y: '0%' }}
                            transition={{
                                duration: '.5', delay: .7
                            }}
                        >
                            <a target="_blank" href={'https://www.linkedin.com/company/isla-architects-eg/'} className=" hover:opacity-60 transition-all ease-in-out duration-500" >
                                <svg fill='#ffffff' className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="linkedin"><path d="M0 5h3.578v11H0zM13.324 5.129c-.038-.012-.074-.025-.114-.036a2.32 2.32 0 0 0-.145-.028A3.207 3.207 0 0 0 12.423 5c-2.086 0-3.409 1.517-3.845 2.103V5H5v11h3.578v-6s2.704-3.766 3.845-1v7H16V8.577a3.568 3.568 0 0 0-2.676-3.448z"></path><circle cx="1.75" cy="1.75" r="1.75"></circle></svg>
                            </a>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, y: '50%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, y: '0%' }}
                            transition={{
                                duration: '.5', delay: 1
                            }}
                        >
                            <a target="_blank" href={'https://twitter.com/isla_architects'} className=" hover:opacity-60 transition-all ease-in-out  duration-500">
                                <svg fill='#ffffff' className="w-5" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="twitter"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"></path></svg>
                            </a>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, y: '50%' }}
                            viewport={{ once: true }}
                            whileInView={{ opacity: 1, y: '0%' }}
                            transition={{
                                duration: '.5', delay: 1.3
                            }}
                        >
                            <a target="_blank" href={'https://www.facebook.com/islaarchitects/'} className=" hover:opacity-60 transition-all ease-in-out  duration-500">
                                <svg fill='#ffffff' className="w-5" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="facebook"><path d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z"></path></svg>
                            </a>
                        </motion.li>

                    </ul>
                </div> */}
            </div>

        </div>
    )
}
