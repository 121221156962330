import { motion } from 'framer-motion';


export const Footer = () => {
  return (
		<footer className=' py-6 bg-gradient-to-b from-[#141414] via-transparent to-[#141414]  bg-opacity-30 relative overflow-hidden flex items-center justify-center flex-col'>
			<img
				className={`transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
				src='/map.svg'
				alt='map'
			/>
			<div className='container mx-auto flex flex-col items-center justify-between '>
				<div className='contact-info _montserrat text-sm font-light flex flex-col items-start px-10 py-5 xl:py-20 justify-center w-full lg:w-2/3 xl:w-1/2 gap-16'>
					<div className='shy flex flex-col gap-1'>
						<motion.h2
							initial={{ opacity: 0, y: '100%' }}
							whileInView={{ opacity: 1, y: '0%' }}
							viewport={{ once: true }}
							transition={{ duration: '.5', delay: 0.5 }}
							className='font-bold text-4xl uppercase'
						>
							Don&apos;t be shy,
						</motion.h2>
						<motion.span
							initial={{ opacity: 0, y: '100%' }}
							whileInView={{ opacity: 1, y: '0%' }}
							viewport={{ once: true }}
							transition={{ duration: '.5', delay: 0.7 }}
							className='font-light text-4xl uppercase _hi overflow-hidden relative h-12 w-44'
						>
							<span className='hi-n transition-all duration-300 ease-in-out absolute top-0 left-0 h-12 '>
								say hi.
							</span>
							<span className='hi-i transition-all duration-300 ease-in-out absolute top-12 left-0 h-12 italic opacity-80'>
								say hi.
							</span>
						</motion.span>
					</div>
					<div className='emails flex flex-wrap gap-10'>
						<motion.span
							initial={{ opacity: 0, y: '100%' }}
							whileInView={{ opacity: 1, y: '0%' }}
							viewport={{ once: true }}
							transition={{ duration: '.5', delay: 0.7 }}
							className={`email   flex flex-col items-start justify-center gap-3 `}
						>
							<span className=' email-title  opacity-50'>E-mail Address:</span>
							<span className='email-address'>info@islaarchitects.com</span>
						</motion.span>

						{/* <motion.span
                          initial={{ opacity: 0, y: '100%' }}
                          whileInView={{ opacity: 1, y: '0%' }}
                          viewport={{ once: true }}
                          transition={{ duration: '.5', delay: 1 }}
                              className={`email   flex flex-col  items-start justify-center gap-3 `}>
                          <span className=" email-title  opacity-50">PR&Collaborations:
                          </span>
                          <span className="email-address">pr@islaarchitects.com</span>
                      </motion.span>

                      <motion.span
                          initial={{ opacity: 0, y: '100%' }}
                          whileInView={{ opacity: 1, y: '0%' }}
                          viewport={{ once: true }}
                          transition={{ duration: '.5', delay: 1.3 }}
                              className={`email   flex flex-col items-start justify-center gap-3 `}>
                          <span className=" email-title  opacity-50">Careers:
                          </span>
                          <span className="email-address">hr@islaarchitects.com</span>
                      </motion.span> */}

						<motion.span
							initial={{ opacity: 0, y: '100%' }}
							whileInView={{ opacity: 1, y: '0%' }}
							viewport={{ once: true }}
							transition={{ duration: '.5', delay: 1.5 }}
							className={`phone   flex flex-col items-start justify-center gap-3 `}
						>
							<span className=' phone-title  opacity-50'>Phone Number #1:</span>
							<span className='phone-number'>+20 115 547 6407</span>
						</motion.span>

						<motion.span
							initial={{ opacity: 0, y: '100%' }}
							whileInView={{ opacity: 1, y: '0%' }}
							viewport={{ once: true }}
							transition={{ duration: '.5', delay: 1.7 }}
							className={`phone   flex flex-col items-start justify-center gap-3 `}
						>
							<span className=' phone-title  opacity-50'>Phone Number #2:</span>
							<span className='phone-number'>+20 106 498 8873</span>
						</motion.span>
					</div>
					<motion.div
						initial={{ opacity: 0, y: '100%' }}
						viewport={{ once: true }}
						whileInView={{ opacity: 1, y: '0%' }}
						transition={{
							duration: '.5',
							delay: 1.8,
						}}
						className={`slogan lg:w-1/2 flex flex-col items-start justify-start gap-1 `}
					>
						<h2 className='uppercase font-semibold text-xl'>
							WE ARE A CLICK AWAY
						</h2>
						<p className='opacity-50'>
							A team of dedicated professionals is available to answer any
							of your inquiries
						</p>
					</motion.div>
					<ul
						className={`social-icons flex items-center justify-center gap-5 transition-all ease-in-out `}
					>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{ duration: '.5', delay: 0.3 }}
						>
							<a
								target='_blank'
								rel='noreferrer'
								href={'https://wa.me/+201155476407'}
								className=' hover:opacity-60 transition-all ease-in-out  duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-5 '
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									id='whatsapp'
								>
									<path d='M16.6 14c-.2-.1-1.5-.7-1.7-.8-.2-.1-.4-.1-.6.1-.2.2-.6.8-.8 1-.1.2-.3.2-.5.1-.7-.3-1.4-.7-2-1.2-.5-.5-1-1.1-1.4-1.7-.1-.2 0-.4.1-.5.1-.1.2-.3.4-.4.1-.1.2-.3.2-.4.1-.1.1-.3 0-.4-.1-.1-.6-1.3-.8-1.8-.1-.7-.3-.7-.5-.7h-.5c-.2 0-.5.2-.6.3-.6.6-.9 1.3-.9 2.1.1.9.4 1.8 1 2.6 1.1 1.6 2.5 2.9 4.2 3.7.5.2.9.4 1.4.5.5.2 1 .2 1.6.1.7-.1 1.3-.6 1.7-1.2.2-.4.2-.8.1-1.2l-.4-.2m2.5-9.1C15.2 1 8.9 1 5 4.9c-3.2 3.2-3.8 8.1-1.6 12L2 22l5.3-1.4c1.5.8 3.1 1.2 4.7 1.2 5.5 0 9.9-4.4 9.9-9.9.1-2.6-1-5.1-2.8-7m-2.7 14c-1.3.8-2.8 1.3-4.4 1.3-1.5 0-2.9-.4-4.2-1.1l-.3-.2-3.1.8.8-3-.2-.3c-2.4-4-1.2-9 2.7-11.5S16.6 3.7 19 7.5c2.4 3.9 1.3 9-2.6 11.4'></path>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{ duration: '.5', delay: 0.5 }}
						>
							<a
								target='_blank'
								rel='noreferrer'
								href={'https://www.instagram.com/isla_architects/'}
								className=' hover:opacity-60 transition-all ease-in-out  duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-5 '
									xmlns='http://www.w3.org/2000/svg'
									data-name='Layer 1'
									viewBox='0 0 24 24'
									id='instagram'
								>
									<path d='M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z'></path>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{
								duration: '.5',
								delay: 0.7,
							}}
						>
							<a
								target='_blank'
								href={'https://twitter.com/isla_architects'}
								className=' hover:opacity-60 transition-all ease-in-out  duration-500'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='w-5 '
									fill='none'
									viewBox='0 0 512 512'
									id='twitter'
								>
									<g clipPath='url(#clip0_84_15697)'>
										<path
											fill='#fff'
											d='M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z'
										></path>
									</g>
									<defs>
										<clipPath id='clip0_84_15697'>
											<rect
												width='512'
												height='512'
												fill='#fff'
											></rect>
										</clipPath>
									</defs>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{ duration: '.5', delay: 0.9 }}
						>
							<a
								target='_blank'
								rel='noreferrer'
								href={'https://www.facebook.com/islaarchitects/'}
								className=' hover:opacity-60 transition-all ease-in-out  duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-5'
									xmlns='http://www.w3.org/2000/svg'
									data-name='Layer 1'
									viewBox='0 0 24 24'
									id='facebook'
								>
									<path d='M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z'></path>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{ duration: '.5', delay: 1.1 }}
						>
							<a
								target='_blank'
								rel='noreferrer'
								href={'https://www.tiktok.com/@isla_architects1'}
								className=' hover:opacity-60 transition-all ease-in-out  duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-4'
									xmlns='http://www.w3.org/2000/svg'
									fillRule='evenodd'
									clipRule='evenodd'
									imageRendering='optimizeQuality'
									shapeRendering='geometricPrecision'
									textRendering='geometricPrecision'
									viewBox='0 0 512 512'
									id='tiktok'
								>
									<path d='M353.97 0.43c0.03,7.81 2.31,120.68 120.76,127.72 0,32.55 0.04,56.15 0.04,87.21 -8.97,0.52 -77.98,-4.49 -120.93,-42.8l-0.13 169.78c1.63,117.84 -85.09,189.55 -198.44,164.78 -195.46,-58.47 -130.51,-348.37 65.75,-317.34 0,93.59 0.05,-0.03 0.05,93.59 -81.08,-11.93 -108.2,55.52 -86.65,103.81 19.6,43.97 100.33,53.5 128.49,-8.53 3.19,-12.14 4.78,-25.98 4.78,-41.52l0 -337.13 86.28 0.43z'></path>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{ duration: '.5', delay: 1.3 }}
						>
							<a
								target='_blank'
								rel='noreferrer'
								href={'https://www.behance.net/islaarchitects'}
								className=' hover:opacity-60 transition-all ease-in-out  duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-5 '
									xmlns='http://www.w3.org/2000/svg'
									data-name='Layer 1'
									viewBox='0 0 24 24'
									id='behance'
								>
									<path d='M20.07,6.35H15V7.76h5.09ZM19,16.05a2.23,2.23,0,0,1-1.3.37A2.23,2.23,0,0,1,16,15.88a2.49,2.49,0,0,1-.62-1.76H22a6.47,6.47,0,0,0-.17-2,5.08,5.08,0,0,0-.8-1.73,4.17,4.17,0,0,0-1.42-1.21,4.37,4.37,0,0,0-2-.45,4.88,4.88,0,0,0-1.9.37,4.51,4.51,0,0,0-1.47,1,4.4,4.4,0,0,0-.95,1.52,5.4,5.4,0,0,0-.33,1.91,5.52,5.52,0,0,0,.32,1.94A4.46,4.46,0,0,0,14.16,17a4,4,0,0,0,1.46,1,5.2,5.2,0,0,0,1.94.34,4.77,4.77,0,0,0,2.64-.7,4.21,4.21,0,0,0,1.63-2.35H19.62A1.54,1.54,0,0,1,19,16.05Zm-3.43-4.12a1.87,1.87,0,0,1,1-1.14,2.28,2.28,0,0,1,1-.2,1.73,1.73,0,0,1,1.36.49,2.91,2.91,0,0,1,.63,1.45H15.41A3,3,0,0,1,15.52,11.93Zm-5.29-.48a3.06,3.06,0,0,0,1.28-1,2.72,2.72,0,0,0,.43-1.58,3.28,3.28,0,0,0-.29-1.48,2.4,2.4,0,0,0-.82-1,3.24,3.24,0,0,0-1.27-.52,7.54,7.54,0,0,0-1.64-.16H2V18.29H8.1a6.55,6.55,0,0,0,1.65-.21,4.55,4.55,0,0,0,1.43-.65,3.13,3.13,0,0,0,1-1.14,3.41,3.41,0,0,0,.37-1.65,3.47,3.47,0,0,0-.57-2A3,3,0,0,0,10.23,11.45ZM4.77,7.86H7.36a4.17,4.17,0,0,1,.71.06,1.64,1.64,0,0,1,.61.22,1.05,1.05,0,0,1,.42.44,1.42,1.42,0,0,1,.16.72,1.36,1.36,0,0,1-.47,1.15,2,2,0,0,1-1.22.35H4.77ZM9.61,15.3a1.28,1.28,0,0,1-.45.5,2,2,0,0,1-.65.26,3.33,3.33,0,0,1-.78.08h-3V12.69h3a2.4,2.4,0,0,1,1.45.41,1.65,1.65,0,0,1,.54,1.39A1.77,1.77,0,0,1,9.61,15.3Z'></path>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{
								duration: '.5',
								delay: 1.5,
							}}
						>
							<a
								target='_blank'
								href={'https://www.linkedin.com/company/isla-architects-eg/'}
								className=' hover:opacity-60 transition-all ease-in-out duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-5 '
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 20 20'
									id='linkedin'
								>
									<path d='M0 5h3.578v11H0zM13.324 5.129c-.038-.012-.074-.025-.114-.036a2.32 2.32 0 0 0-.145-.028A3.207 3.207 0 0 0 12.423 5c-2.086 0-3.409 1.517-3.845 2.103V5H5v11h3.578v-6s2.704-3.766 3.845-1v7H16V8.577a3.568 3.568 0 0 0-2.676-3.448z'></path>
									<circle
										cx='1.75'
										cy='1.75'
										r='1.75'
									></circle>
								</svg>
							</a>
						</motion.li>
						<motion.li
							initial={{ opacity: 0, y: '100%' }}
							viewport={{ once: true }}
							whileInView={{ opacity: 1, y: '0%' }}
							transition={{ duration: '.5', delay: 1.7 }}
						>
							<a
								target='_blank'
								rel='noreferrer'
								href={'https://www.youtube.com/@isla_architects.'}
								className=' hover:opacity-60 transition-all ease-in-out duration-500'
							>
								<svg
									fill='#ffffff'
									className='w-5 '
									xmlns='http://www.w3.org/2000/svg'
									data-name='Layer 1'
									viewBox='0 0 24 24'
									id='youtube'
								>
									<path d='M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z'></path>
								</svg>
							</a>
						</motion.li>
					</ul>
				</div>
				<div className='w-full flex flex-col md:flex-row items-center justify-between p-10 gap-16'>
					<motion.p
						initial={{ opacity: 0, y: '100%' }}
						whileInView={{ opacity: 0.6, y: '0%' }}
						transition={{ duration: '.5', delay: 0.5 }}
						viewport={{ once: true }}
						className='copy-rights opacity-30 font-light text-xs md:w-3/5 lg:w-2/5  leading-6 '
					>
						Please, be informed, that the intellectual property rights to all
						the photos, designs and other materials on this Site belong to isla
						Architects. You may request permission to use them by contacting us
						at: privacy@islaarchitects.com
					</motion.p>
					<motion.div
						initial={{ opacity: 0, y: '100%' }}
						viewport={{ once: true }}
						whileInView={{ opacity: 1, y: '0%' }}
						transition={{
							duration: '.5',
							delay: 0.2,
						}}
						className='by flex items-center justify-end gap-5 '
					>
						<img
							className='w-64 md:w-44 pointer-events-none'
							src='/isla_logo.png'
							alt='isla architects logo'
							width='300'
							height='300'
						/>
						{/* <span className='font-light opacity-50'>developed by </span>
                        <Link href='/' className='font-bold capitalize transition-all duration-500 ease-in-out '>abdurrahman ahmed</Link> */}
					</motion.div>
				</div>
			</div>
			<a
				href='https://www.developedbyabdu.com'
				target='_blank'
				className=' font-light transition-all duration-300 text-xs md:w-3/5 lg:w-2/5 w-full text-center leading-6 text-white/70 hover:text-white/100'
			>
				developedByAbdu.com
			</a>
		</footer>
	);
}
