import ProImage from "./ProImage"
import { motion } from 'framer-motion';
import { ProjectSlider } from './ProjectSlider';
import { useFormContext } from '../../context/FormContext';
export const ProjectImages = ({ project }) => {
	const { currImg } = useFormContext();
	return (
		<>
			<div className='p-10 relative my-20'>
				<div className='bg-title absolute top-10 text-[#222] left-1/2 -translate-x-1/2 pointer-events-none uppercase text-6xl md:text-[9rem] lg:text-[12rem] tracking-widest -z-10 overflow-hidden w-full text-center'>
					<motion.span
						initial={{ opacity: 0, y: '50%' }}
						whileInView={{ opacity: 1, y: '0%' }}
						viewport={{ once: true }}
						transition={{ duration: '.5', delay: 0.5 }}
					>
						gallery
					</motion.span>
				</div>
				<h3 className=' uppercase text-xl md:text-2xl mb-5 lg:text-3xl py-10 font-semibold text-center'>
					project's gallery
				</h3>
				<div
					id='projectImagesContainer'
					className='w-full flex flex-wrap justify-center items-center gap-10 max-h-min '
				>
					{project?.images?.map((i, idx) => {
						return (
							<ProImage
								key={project.id}
								project={project}
								proImg={i}
								idx={idx}
							/>
						);
					})}
				</div>
			</div>
			<ProjectSlider
				project={project}
				proImg={project.images[currImg]}
				ImagesNum={project.images.length}
			/>
		</>
	);
};
