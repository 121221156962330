import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const About = () => {
  return (
			<motion.div
				initial={{ opacity: 0, y: '50%' }}
				whileInView={{ opacity: 1, y: '0%' }}
				viewport={{ once: true }}
				transition={{ duration: '.5' }}
				className=' relative text-white px-5 py-24 md:py-44 lg:py-64 '
			>
				<div className='container flex items-center justify-center mx-auto '>
					<div className='bg-title absolute top-10 [#222222] left-1/2 -translate-x-1/2 pointer-events-none uppercase text-7xl md:top-20 md:text-9 lg:top-24 lg:text-[14rem] xl:text-[18rem] tracking-widest z-0 opacity-10 overflow-hidden w-full text-center'>
						<motion.span
							initial={{ opacity: 0, y: '50%' }}
							whileInView={{ opacity: 1, y: '0%' }}
							viewport={{ once: true }}
							transition={{ duration: '.5', delay: 0.5 }}
						>
							about
						</motion.span>
					</div>
					<span className='vertical-line h-0 md:h-14 lg:h-24 absolute  md:top-12 lg:top-16 left-1/2 -translate-x-1/2 w-[.5px] opacity-20 bg-white'></span>
					<span className='vertical-line h-0 md:h-14 lg:h-24 absolute  md:bottom-12 lg:bottom-16 left-1/2 -translate-x-1/2 w-[.5px] opacity-20 bg-white'></span>
					<div className='about  w-full  lg:w-2/3 p-5 gap-20 flex flex-col items-start justify-center '>
						<div className='title font-semibold text-3xl md:text-4xl text-start md:w-3/5 lg:w-96 z-10'>
							<motion.h2
								initial={{ opacity: 0, y: '50%' }}
								whileInView={{ opacity: 1, y: '0%' }}
								viewport={{ once: true }}
								transition={{ duration: '.5', delay: 0.5 }}
							>
								We design every project as a one-off{' '}
							</motion.h2>
						</div>
						<div className='content text-md _montserrat font-light flex flex-col md:flex-row items-center justify-between  gap-20 z-10 lg:leading-8 '>
							<div className='flex flex-col gap-3  md:w-1/2'>
								<motion.p
									initial={{ opacity: 0, y: '50%' }}
									whileInView={{ opacity: 0.6, y: '0%' }}
									viewport={{ once: true }}
									transition={{ duration: '.5', delay: 0.2 }}
								>
									A leading real estate company of dedicated professionals in
									Egypt, creating a knowledge-based approach in design and
									architecture, diverse sustainable communities.
								</motion.p>
								<motion.p
									initial={{ opacity: 0, y: '50%' }}
									whileInView={{ opacity: 0.6, y: '0%' }}
									viewport={{ once: true }}
									transition={{ duration: '.5', delay: 0.4 }}
								>
									With a +85 projects around the world, from Kuwait, ksa,
									Singapore, Egypt and more; isla has developed a diverse
									portfolio of Class +B/-A, offers residential options that
									cater to your different needs.
								</motion.p>
							</div>
							<Link
								className=' opacity-60 hover:opacity-100  transition-all duration-500'
								to={'/projects/all'}
							>
								<motion.p
									initial={{ opacity: 0, y: '50%' }}
									whileInView={{ opacity: 1, y: '0%' }}
									viewport={{ once: true }}
									transition={{ duration: '.5', delay: 0.5 }}
									className='flex flex-col gap-5 text-center'
								>
									{' '}
									<span className='text-8xl lg:text-9xl font-semibold '>
										115+
									</span>
									<span className='text-xs lg:text-sm  text-center  uppercase tracking-wide font-bold transition-all duration-500 '>
										completed projects to date &gt;&gt;
									</span>
								</motion.p>
							</Link>
						</div>
					</div>
				</div>
			</motion.div>
	);
}
