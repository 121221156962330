import { motion } from 'framer-motion';
import { useState } from 'react';
import { useFormContext } from '../../context/FormContext';

export default function ProImage({ project, proImg, idx }) {
	const [showMax, setShowMax] = useState(false);
	const { setIsSliderOpened, setCurrImg } = useFormContext();
	const maximize = () => {
		console.log(idx);
		setIsSliderOpened(true);
		setCurrImg(idx);
	};
	return (
		<motion.div
			initial={{ opacity: 0, y: '15%' }}
			whileInView={{ opacity: 1, y: '0' }}
			viewport={{ once: true }}
			transition={{
				duration: '.5',
				ease: 'easeInOut',
			}}
			className='md:w-2/5 md:min-w-2/5  bg-black border-2 border-[#141414] relative overflow-hidden cursor-pointer'
			onMouseOver={() => {
				setShowMax(true);
			}}
			onMouseLeave={() => {
				setShowMax(false);
			}}
			onClick={() => {
				maximize();
			}}
		>
			<div
				className={`${
					showMax ? 'opacity-100' : 'opacity-0'
				} transition-all duration-300 absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 z-20 flex  items-center justify-center `}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					enableBackground='new 0 0 512 512'
					className='w-20 opacity-90'
					viewBox='0 0 512 512'
					id='maximize'
					fill='#fff'
				>
					<path
						d='M310.4,203c-3.1-3.1-3.1-8.2,0-11.3L481.7,20.4L451,26.6c-4.3,0.9-8.6-1.9-9.4-6.3c-0.9-4.3,1.9-8.6,6.3-9.4l53-10.7
	c0.5-0.1,0.9-0.2,1.4-0.2c1,0,1.9,0.1,2.8,0.4c1.1,0.4,2.1,0.9,3,1.7c0.9,0.8,1.6,1.8,2,2.9c0.3,0.8,0.5,1.7,0.6,2.7
	c0,0.5,0,1-0.1,1.4l-6.3,53.7c-0.5,4.1-3.9,7.1-7.9,7.1c-0.3,0-0.6,0-0.9-0.1c-4.4-0.5-7.5-4.5-7-8.9l3.2-27.9L321.7,203
	c-1.6,1.6-3.6,2.3-5.7,2.3S311.9,204.6,310.4,203z M192.7,309.5L20,482.1l3.6-31.1c0.5-4.4-2.6-8.4-7-8.9c-4.4-0.5-8.4,2.6-8.9,7
	l-6.3,53.7c-0.1,0.5-0.1,1-0.1,1.4c0,0.9,0.2,1.9,0.6,2.7c0.5,1.1,1.2,2.1,2,2.9c0.8,0.8,1.8,1.4,3,1.7c0.8,0.2,1.6,0.4,2.4,0.4
	c0.1,0,0.2,0,0.3,0c0.5,0,1-0.1,1.4-0.2l53-10.7c4.3-0.9,7.1-5.1,6.3-9.4c-0.9-4.3-5.1-7.1-9.4-6.3l-27.2,5.5L204,320.8
	c3.1-3.1,3.1-8.2,0-11.3S195.8,306.3,192.7,309.5z M33.2,21L61,26.6c4.3,0.9,8.6-1.9,9.4-6.3s-1.9-8.6-6.3-9.4l-53-10.7
	C10.7,0.1,10.2,0,9.7,0C8.8,0,7.9,0.1,7,0.4c-1.2,0.4-2.2,1-3.1,1.8C3.1,2.9,2.4,3.9,2,5C1.6,5.8,1.4,6.7,1.4,7.7c0,0.5,0,1,0.1,1.4
	l6.3,53.7c0.5,4.1,3.9,7.1,7.9,7.1c0.3,0,0.6,0,0.9-0.1c4.4-0.5,7.5-4.5,7-8.9l-3.6-30.5L192.7,203c1.6,1.6,3.6,2.3,5.7,2.3
	s4.1-0.8,5.7-2.3c3.1-3.1,3.1-8.2,0-11.3L33.2,21z M504.3,449.2c-0.5-4.4-4.5-7.5-8.9-7c-4.4,0.5-7.5,4.5-7,8.9l3.3,28.5l-170-170
	c-3.1-3.1-8.2-3.1-11.3,0s-3.1,8.2,0,11.3l170.7,170.7l-30.1-6.1c-4.3-0.9-8.6,1.9-9.4,6.3c-0.9,4.3,1.9,8.6,6.3,9.4l53,10.7
	c0.5,0.1,0.9,0.2,1.4,0.2c0.1,0,0.2,0,0.3,0c0.8,0,1.6-0.1,2.4-0.4c1.2-0.4,2.2-1,3.1-1.8c0.8-0.8,1.5-1.7,1.9-2.8
	c0.3-0.8,0.6-1.8,0.6-2.7c0-0.5,0-1-0.1-1.4L504.3,449.2z'
					></path>
				</svg>
			</div>

			<img
				className={`pointer-events-none object-cover min-h-full min-w-full z-10  `}
				src={proImg}
				alt={project.name}
			/>
		</motion.div>
	);
}
