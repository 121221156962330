import { useState } from "react"
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";

export const Sections = () => {


  const [currSection, setCurrSection] = useState('')
    const handleHover = (e) => {
        if(e.target.id){

            setCurrSection(e.target.id)
        }
    }

  return (
		<section
			onMouseLeave={() => {
				setCurrSection('');
			}}
			onTouchEnd={() => {
				setCurrSection('');
			}}
			className='relative overflow-hidden h-[80vh] md:h-[90vh]  _montserrat bg-opacity-60 flex flex-col md:flex-row items-center justify-center gap-0'
		>
			<img
				className={`${
					currSection === '01' ? ' opacity-100' : ' opacity-0'
				} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
				src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/b9111c162119781.63d062f66db88.jpg'
				alt={currSection}
			/>
			<img
				className={`${
					currSection === '02' ? ' opacity-100' : ' opacity-0'
				} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
				src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/8625ef159835075.63a6d3bad7f99.jpg'
				alt={currSection}
			/>
			<img
				className={`${
					currSection === '03' ? ' opacity-100' : ' opacity-0'
				} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
				src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/bfb13f166988087.6421c834ec4bc.jpg'
				alt={currSection}
			/>
			<img
				className={`${
					currSection === '04' ? ' opacity-100' : ' opacity-0'
				} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
				src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/18b7b0166988087.6421c83500e8e.jpg'
				alt={currSection}
			/>

			{/* &&&&&&&&&&&&&&&&&&&&&&&&& section 01 &&&&&&&&&&&&&&&&&&&&&&&&& */}
			<motion.div
				initial={{ opacity: 0, y: '50%' }}
				viewport={{ once: true }}
				whileInView={{ opacity: 1, y: '0%' }}
				transition={{
					duration: '.3',
				}}
				className='w-full h-1/4 md:w-1/4 md:h-full'
			>
				<Link
					onTouchStart={(e) => {
						handleHover(e);
					}}
					onMouseOver={(e) => {
						handleHover(e);
					}}
					id='01'
					to={'/projects/interior-design'}
					className={`${
						currSection ? 'border border-[#141414]' : ''
					} section relative overflow-hidden bg-black bg-opacity-10  p-5 flex flex-col items-start md:items-center justify-center w-full h-full`}
				>
					<img
						className={`${
							currSection ? ' opacity-0' : ' opacity-100'
						} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
						src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/b9111c162119781.63d062f66db88.jpg'
						alt='Interior Design'
					/>
					<h2
						className={`${
							!currSection || currSection === '01'
								? 'opacity-100'
								: 'opacity-30'
						} capitalize pointer-events-none transition-all duration-500 ease-in-out text-2xl lg:text-3xl font-normal`}
					>
						interior design
					</h2>

					<div
						className={`${
							currSection === '01'
								? 'left-[55%] lg:top-[80%] md:top-[70%]'
								: 'left-full md:top-full lg:top-full opacity-0 '
						} section-demo flex items-center justify-center text-[.7rem]  bottom-0 top-0 right-0 md:text-xs  md:bottom-0 md:left-0 md:right-0 lg:text-sm  absolute p-5 bg-[#141414] z-20  font-normal transition-all duration-500 ease-in-out`}
					>
						Designing dynamic and efficient environments for businesses,
						focusing on functionality, branding, and user experience.
					</div>
				</Link>
			</motion.div>
			{/* &&&&&&&&&&&&&&&&&&&&&&&&& section 02 &&&&&&&&&&&&&&&&&&&&&&&&& */}
			<motion.div
				initial={{ opacity: 0, y: '50%' }}
				viewport={{ once: true }}
				whileInView={{ opacity: 1, y: '0%' }}
				transition={{
					duration: '.5',
				}}
				className='w-full h-1/4 md:w-1/4 md:h-full'
			>
				<Link
					onTouchStart={(e) => {
						handleHover(e);
					}}
					onMouseOver={(e) => {
						handleHover(e);
					}}
					id='02'
					to={'/projects/site-execution'}
					className={`${
						currSection ? 'border border-[#141414]' : ''
					} section relative overflow-hidden bg-black bg-opacity-10 w-full h-full p-5 flex flex-col items-start md:items-center justify-center `}
				>
					<img
						className={`${
							currSection ? ' opacity-0' : ' opacity-100'
						} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
						src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/8625ef159835075.63a6d3bad7f99.jpg'
						alt='Site Execution'
					/>
					<h2
						className={`${
							!currSection || currSection === '02'
								? 'opacity-100'
								: 'opacity-30'
						} capitalize pointer-events-none transition-all duration-500 ease-in-out text-2xl lg:text-3xl font-normal`}
					>
						site execution
					</h2>
					<div
						className={`${
							currSection === '02'
								? 'left-[55%] lg:top-[80%] md:top-[70%]'
								: 'left-full md:top-full lg:top-full opacity-0 '
						} section-demo flex items-center justify-center text-[.7rem]  bottom-0 top-0 right-0 md:text-xs  md:bottom-0 md:left-0 md:right-0 lg:text-sm  absolute p-5 bg-[#141414] z-20  font-normal transition-all duration-500 ease-in-out`}
					>
						Designing dynamic and efficient environments for businesses,
						focusing on functionality, branding, and user experience.
					</div>
				</Link>
			</motion.div>
			{/* &&&&&&&&&&&&&&&&&&&&&&&&& section 03 &&&&&&&&&&&&&&&&&&&&&&&&& */}
			<motion.div
				initial={{ opacity: 0, y: '50%' }}
				viewport={{ once: true }}
				whileInView={{ opacity: 1, y: '0%' }}
				transition={{
					duration: '.7',
				}}
				className='w-full h-1/4 md:w-1/4 md:h-full'
			>
				<Link
					onTouchStart={(e) => {
						handleHover(e);
					}}
					onMouseOver={(e) => {
						handleHover(e);
					}}
					id='03'
					to={'/projects/architects'}
					className={`${
						currSection ? 'border border-[#141414]' : ''
					} section relative overflow-hidden bg-black bg-opacity-10 w-full h-full p-5 flex flex-col items-start md:items-center justify-center `}
				>
					<img
						className={`${
							currSection ? ' opacity-0' : ' opacity-100'
						} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
						src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/bfb13f166988087.6421c834ec4bc.jpg'
						alt='Architects'
					/>
					<h2
						className={`${
							!currSection || currSection === '03'
								? 'opacity-100'
								: 'opacity-30'
						} capitalize pointer-events-none transition-all duration-500 ease-in-out text-2xl lg:text-3xl font-normal`}
					>
						architects
					</h2>
					<div
						className={`${
							currSection === '03'
								? 'left-[55%] lg:top-[80%] md:top-[70%]'
								: 'left-full md:top-full lg:top-full opacity-0 '
						} section-demo flex items-center justify-center text-[.7rem]  bottom-0 top-0 right-0 md:text-xs  md:bottom-0 md:left-0 md:right-0 lg:text-sm  absolute p-5 bg-[#141414] z-20  font-normal transition-all duration-500 ease-in-out`}
					>
						Designing dynamic and efficient environments for businesses,
						focusing on functionality, branding, and user experience.
					</div>
				</Link>
			</motion.div>
			{/* &&&&&&&&&&&&&&&&&&&&&&&&& section 04 &&&&&&&&&&&&&&&&&&&&&&&&& */}
			<motion.div
				initial={{ opacity: 0, y: '50%' }}
				viewport={{ once: true }}
				whileInView={{ opacity: 1, y: '0%' }}
				transition={{
					duration: '1',
				}}
				className='w-full h-1/4 md:w-1/4 md:h-full'
			>
				<Link
					onTouchStart={(e) => {
						handleHover(e);
					}}
					onMouseOver={(e) => {
						handleHover(e);
					}}
					id='04'
					to={'/projects/kitchens'}
					className={`${
						currSection ? 'border border-[#141414]' : ''
					} section relative overflow-hidden bg-black bg-opacity-10 w-full h-full p-5 flex flex-col items-start md:items-center justify-center `}
				>
					<img
						className={`${
							currSection ? ' opacity-0' : ' opacity-100'
						} transition-all duration-500 ease-in-out object-cover min-h-full min-w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  -z-10`}
						src='https://mir-s3-cdn-cf.behance.net/project_modules/1400/18b7b0166988087.6421c83500e8e.jpg'
						alt='Kitchens'
					/>
					<h2
						className={`${
							!currSection || currSection === '04'
								? 'opacity-100'
								: 'opacity-30'
						} capitalize pointer-events-none transition-all duration-500 ease-in-out text-2xl lg:text-3xl font-normal`}
					>
						kitchens
					</h2>
					<h3
						className={`${
							!currSection || currSection === '04'
								? 'opacity-100'
								: 'opacity-30'
						} uppercase             pointer-events-none transition-all duration-500 ease-in-out text-xs tracking-widest font-thin `}
					>
						design & build
					</h3>
					<div
						className={`${
							currSection === '04'
								? 'left-[55%] lg:top-[80%] md:top-[70%]'
								: 'left-full md:top-full lg:top-full opacity-0 '
						} section-demo flex items-center justify-center text-[.7rem]  bottom-0 top-0 right-0 md:text-xs  md:bottom-0 md:left-0 md:right-0 lg:text-sm  absolute p-5 bg-[#141414] z-20  font-normal transition-all duration-500 ease-in-out`}
					>
						Designing dynamic and efficient environments for businesses,
						focusing on functionality, branding, and user experience.
					</div>
				</Link>
			</motion.div>
		</section>
	);
}
