import { motion } from 'framer-motion';
import { useFormContext } from '../../context/FormContext';
export const ProjectSlider = ({ project, proImg, ImagesNum }) => {
	const { setCurrImg, currImg, isSliderOpened, setIsSliderOpened } =
		useFormContext();
	const handleSlider = (e) => {
		if (e.target.id === 'projectSlider') {
			setIsSliderOpened(false);
		}
	};
	const handleNext = () => {
		if (0 <= currImg && currImg < ImagesNum && currImg !== ImagesNum - 1) {
			setCurrImg(currImg + 1);
		} else {
			setCurrImg(0);
		}
	};
	const handlePrev = () => {
		if (0 < currImg && currImg < ImagesNum) {
			setCurrImg(currImg - 1);
		} else if (currImg === 0) {
			setCurrImg(ImagesNum - 1);
		}
	};
	return (
		<div
			onClick={(e) => {
				handleSlider(e);
			}}
			id='projectSlider'
			className={` ${
				isSliderOpened ? 'opacity-100' : 'opacity-0 pointer-events-none'
			} transition-all duration-300 flex flex-col items-center justify-center md:p-10 fixed z-50 top-0 left-0 bottom-0 right-0 bg-[#141414] bg-opacity-90 p-5 h-full `}
		>
			<motion.div
				initial={{ opacity: 0, y: '15%' }}
				whileInView={{ opacity: 1, y: '0' }}
				viewport={{ once: true }}
				transition={{
					duration: '.5',
					ease: 'easeInOut',
				}}
				className='overflow-hidden cursor-move my-auto md:w-2/3'
			>
				<img
					className={`pointer-events-none object-contain   z-10  `}
					src={proImg}
					alt={project.name}
				/>
			</motion.div>
			<div className='controls  bg-black/75 hover:bg-black transition-all duration-300 text-white p-2'>
				<div className='flex items-center justify-center gap-2 '>
					<span
						onClick={() => {
							handlePrev();
						}}
						className='cursor-pointer fill-current opacity-50 hover:opacity-100 transition-all'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='w-8 '
							fill='#fff'
							viewBox='0 0 24 24'
							id='arrow-left'
						>
							<path
								fill='#fff'
								fillRule='evenodd'
								d='M11.1768 4.82322C11.2744 4.92085 11.2744 5.07915 11.1768 5.17678L4.60355 11.75L20 11.75C20.1381 11.75 20.25 11.8619 20.25 12C20.25 12.1381 20.1381 12.25 20 12.25L4.60355 12.25L11.1768 18.8232C11.2744 18.9209 11.2744 19.0791 11.1768 19.1768C11.0791 19.2744 10.9209 19.2744 10.8232 19.1768L3.82322 12.1768C3.72559 12.0791 3.72559 11.9209 3.82322 11.8232L10.8232 4.82322C10.9209 4.72559 11.0791 4.72559 11.1768 4.82322Z'
								clipRule='evenodd'
							></path>
						</svg>
					</span>
					<span className='font-light  pointer-events-none'>{currImg + 1}</span>
					<span
						onClick={() => {
							handleNext();
						}}
						className='cursor-pointer fill-current opacity-50 hover:opacity-100 transition-all'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 256 256'
							id='arrow-right'
							className='w-8 '
						>
							<rect
								width='256'
								height='256'
								fill='none'
							></rect>
							<line
								x1='40'
								x2='216'
								y1='128'
								y2='128'
								fill='none'
								stroke='#fff'
								stroke-linecap='round'
								stroke-linejoin='round'
								stroke-width='8'
							></line>
							<polyline
								fill='none'
								stroke='#fff'
								stroke-linecap='round'
								stroke-linejoin='round'
								stroke-width='8'
								points='144 56 216 128 144 200'
							></polyline>
						</svg>
					</span>
				</div>
			</div>
		</div>
	);
};
