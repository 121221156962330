import { Navigate, useParams } from "react-router-dom"
import projects from "../../projects.json"

export const Redirect = ({children,currPage}) => {


    const { category, projectDetails } = useParams();
    let project = projects.find((p) => p.id === projectDetails);
    let section = projects.find((p) => p.section === category);





    if (currPage ==='Projects'){

    if (section || category=== 'all'){

        return <>{children}</>
    }
    
    
    } 
    
    if(currPage ==='ProjectDetails'){
    
    if (category=== 'all'&& project){

        return <>{children}</>
    } else if(section && project){
        return <>{children}</>


    }


}

  return <Navigate to={'/404'} />
}
